import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import {
    DetailedInformationDepartmentsServiceSandbox
} from '@/pages/detailed-information-departments-service/sandbox/detailed-information-departments-service.sandbox';
import TableComponent from '@/core/shared/table/table.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import DepMobileComponent
    from '@/pages/detailed-information-departments-service/components/dep-mobile/dep-mobile.component.vue';

@Options({
    name: 'voen-search-component',
    props: {
        totalCount: {
            type: [Number, String]
        }
    },
    components: {
        TableComponent,
        NotDataComponent,
        DepMobileComponent,
    }

})

export default class VoenSearchComponent extends Vue {
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public voen = '';
    public pageNumber = 1;
    public pageSize = 10;
    public refreshPagination = false;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };
    public errorVoen = false;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.detailedInformationDepartmentsService) {
                this.locale = res.detailedInformationDepartmentsService;
            }
        });
    }

    public mounted() {
        this.store.commit('SET_RESET_INFORMATION_ORGANIZATION');
    }

    public unmounted() {
        this.store.commit('SET_RESET_INFORMATION_ORGANIZATION');
    }

    public checkInput() {
        this.voen = this.voen.replace(/[^0-9]+/g, '').slice(0, 10);
    }

    public validation() {
        if (this.voen.trim()) {
            this.errorVoen = false;
        } else {
            this.errorVoen = true;
        }
    }

    public sendData() {
        this.validation();
        if (!this.errorVoen) {
            this.$emit('voen', this.voen);
        }
    }
}

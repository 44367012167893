import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'dep-mobile-component',
    props: {
        item: {
            type: Object
        }
    }
})

export default class DepMobileComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.detailedInformationDepartmentsService) {
                this.locale = res.detailedInformationDepartmentsService;
            }
        });
    }
}
import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import {
    DetailedInformationDepartmentsServiceSandbox
} from '@/pages/detailed-information-departments-service/sandbox/detailed-information-departments-service.sandbox';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import VoenSearchComponent
    from '@/pages/detailed-information-departments-service/components/voen-search/voen-search.component.vue';
import PhoneSearchComponent
    from '@/pages/detailed-information-departments-service/components/phone-search/phone-search.component.vue';
import TableComponent from '@/core/shared/table/table.component.vue';
import OrganizationTableComponent
    from '@/pages/detailed-information-departments-service/components/organization-table/organization-table.component.vue';
import DepMobileComponent
    from '@/pages/detailed-information-departments-service/components/dep-mobile/dep-mobile.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IInformationOrganization} from '@/pages/detailed-information-departments-service/types/voen';
import TabRadioComponent from '@/core/shared/tab-radio/tab-radio.component.vue';
import OrganizationInfoComponent
    from '@/pages/detailed-information-departments-service/components/organization-info/organization-info.component.vue';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'privacy-component',
    components: {
        ServiceNameComponent,
        VoenSearchComponent,
        PhoneSearchComponent,
        TableComponent,
        OrganizationTableComponent,
        TabRadioComponent,
        DepMobileComponent,
        NotDataComponent,
        OrganizationInfoComponent
    }
})

export default class DetailedInformationDepartmentsService extends mixins(Router)  {
    private sandbox: DetailedInformationDepartmentsServiceSandbox = container.resolve(DetailedInformationDepartmentsServiceSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    public voen = '';
    public phone = '';
    public pageNumber = 1;
    public pageSize = 10;
    public view = 1;
    public service = 1154;
    private store = store;
    public locale$: any = locale;
    public locale: any = null;

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get organizations(): IInformationOrganization | null {
        return this.store.getters._getOrganization;
    }

    get totalCountOrganization() {
        return this.store.getters._getTotalCountOrganization;
    }

    get name(): string | null {
        return this.store.getters._getOrganizationName;
    }

    get organizationVoen(): string | null {
        return this.store.getters._getOrganizationVoen;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.detailedInformationDepartmentsService) {
                this.locale = res.detailedInformationDepartmentsService;
            }
        });
    }
    public  mounted() {
        this.getServiceData();
    }

    public checkOrganizationPhone(info: any) {
        this.voen = '';
        this.phone = info;
        this.sandbox.getPhone({
            number: this.phone,
            pageNumber: 1,
            pageSize: this.pageSize,
        });
    }

    public checkOrganizationVoen(info: any) {
        this.phone = '';
        this.voen = info;
        this.sandbox.getVoen({
            voen: this.voen,
            pageNumber: 1,
            pageSize: this.pageSize,
        });
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        if (this.view === 1) {
            this.sandbox.getPhone({
                number: this.phone,
                pageNumber: info.pageNumber,
                pageSize: info.pageSize,
            });
        } else {
            this.sandbox.getVoen({
                voen: this.voen,
                pageNumber: info.pageNumber,
                pageSize: info.pageSize,
            });
        }
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }

    public changeViewTab(info: number) {
        this.view = info;
    }

    public unmounted() {
        this.store.commit('SET_RESET_INFORMATION_ORGANIZATION');
    }

}

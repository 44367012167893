import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'organization-info-component',
    props: {
        name: {
            type: [String, null]
        },
        tin: {
            type: [String, null]
        }
    }
})

export default class OrganizationInfoComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.detailedInformationDepartmentsService) {
                this.locale = res.detailedInformationDepartmentsService;
            }
        });
    }
}
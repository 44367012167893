<style src="./detailed-information-departments-service.component.scss" lang="scss"></style>

<template>
  <div class="detailed-information-departments mb-100">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section class="mt-20">
            <v-card class="h-container">
              <tab-radio-component
                  @changeViewTab="changeViewTab"
                  :nameRightInput="locale.radioVoen"
                  :nameLeftInput="locale.radioPhone"/>
              <phone-search-component
                  v-if="view === 1"
                  :totalCount="totalCountOrganization"
                  @phone="checkOrganizationPhone"/>
              <voen-search-component
                  v-else-if="view === 2"
                  :totalCount="totalCountOrganization"
                  @voen="checkOrganizationVoen"
              />
              <organization-info-component
                  v-if="organizations?.length> 0"
                  :name="name" :voen="organizationVoen"/>

              <div class="d-none d-lg-block">
                <organization-table-component
                    id="table"
                    :organizations="organizations"
                    v-if="totalCountOrganization>0"/>
                <v-pagination
                    ref="pagination"
                    :limit="pageSize"
                    :dataCount="totalCountOrganization"
                    :page="pageNumber"
                    @changePage="changePage"
                    class="mt-40 mb-100  "/>
              </div>
              <not-data-component class="mt-40" v-if="organizations?.length === 0">
                <div v-if="phone.length>0"> {{ locale.notPhone }}</div>
                <div v-if="voen.length>0"> {{ locale.notVoen }}</div>
              </not-data-component>
            </v-card>
            <div class="d-lg-none d-block">
              <dep-mobile-component
                  v-for="item in organizations"
                  :item="item"
                  :key="item.queueNumber"
              />
              <v-pagination
                  ref="pagination"
                  :limit="pageSize"
                  :dataCount="totalCountOrganization"
                  :page="pageNumber"
                  @changePage="changePage"
                  class="mt-40 mb-100  "/>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./detailed-information-departments-service.component.ts"></script>

<style src="./dep-mobile.component.scss" lang="scss"></style>

<template>
  <div class="dep-mobile mt-10">
    <v-card>
      <div class="information">
        <div class="information__title">
          {{ locale.phone }}
        </div>
        <div class="information__description">
          {{ item.phone }}
        </div>
      </div>
      <div class="information">
        <div class="information__title">
          {{ locale.address }}
        </div>
        <div class="information__description">
          {{ item.address }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script src="./dep-mobile.component.ts"></script>
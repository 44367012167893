<style src="./phone-search.component.scss" lang="scss"></style>

<template>
  <div class="phone-search mt-40">
    <form class="form " @submit.prevent="sendData">
      <div class="row">
        <div class="col-lg-6 col-12">
          <label for="codeSearch" class="v-label">{{ locale.phone }}</label>
          <div class="form__group d-lg-flex ">
            <input
                :class="{'v-input--error':this.errorPhone}"
                @keyup="deleteNumber($event)"
                @keydown="deleteNumber($event)"
                v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                v-model="phone"
                placeholder="+994"
                type="text" class="v-input mr-20" id="codeSearch">
            <v-divider class="d-lg-none d-block mt-30 mb-30"/>
            <div class="btn-wrapper">
              <v-btn
                  color="dark">{{ locale.search }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./phone-search.component.ts"></script>

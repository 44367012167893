import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import TableComponent from '@/core/shared/table/table.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import DepMobileComponent
    from '@/pages/detailed-information-departments-service/components/dep-mobile/dep-mobile.component.vue';

@Options({
    name: 'phone-search-component',
    props: {
        totalCount: {
            type: [Number, String]
        }
    },
    components: {
        TableComponent,
        NotDataComponent,
        DepMobileComponent
    }

})

export default class PhoneSearchComponent extends Vue {
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public phone = '+994(';
    public pageNumber = 1;
    public pageSize = 10;
    public refreshPagination = false;
    public errorPhone = false;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    public deleteNumber(event: any) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (this.phone.length <= 5) {
                this.phone = '+994(    ';
            }
        }
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.detailedInformationDepartmentsService) {
                this.locale = res.detailedInformationDepartmentsService;
            }
        });
    }

    public mounted() {
        this.store.commit('SET_RESET_INFORMATION_ORGANIZATION');
    }

    public unmounted() {
        this.store.commit('SET_RESET_INFORMATION_ORGANIZATION');
    }

    public validation() {
        let numberLength = this.phone.replace(/[^0-9]+/g, '').slice(3).length;
        if (numberLength === 9) {
            this.phone = this.phone.replace(/[^0-9]+/g, '').slice(3);
            this.errorPhone = false;
        } else {
            this.errorPhone = true;
        }
    }

    public sendData() {
        this.validation();
        if (!this.errorPhone) {
            this.$emit('phone', this.phone);
        }
    }

}

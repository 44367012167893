import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class DetailedInformationDepartmentsServiceSandbox {
    private store = store;

    getVoen(payload: any): any {
        return this.store.dispatch('getVoen', payload);
    }

    getPhone(payload: any): any {
        return this.store.dispatch('getPhone', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }


}

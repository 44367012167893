<style src="./organization-table.component.scss" lang="scss"></style>

<template>
  <div class="organization-table d-lg-block d-none">
    <table-component class="mt-40" id="table" v-if="organizations?.length>0">
      <thead>
      <tr>
        <th class="text-center">{{ locale.queue }}</th>
        <th>{{ locale.phone }}</th>
        <th>{{ locale.address }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in organizations" :key="index">
        <th class="text-center">{{ item.queueNumber }}</th>
        <th>{{ item.phone }}</th>
        <th>{{ item.address }}</th>
      </tr>
      </tbody>
    </table-component>
  </div>
</template>

<script src="./organization-table.component.ts"></script>
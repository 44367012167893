<style src="./organization-info.component.scss" lang="scss"></style>

<template>
  <div class="organization-info">
    <div class="organization-info__wrapper">
      <div class="organization-info__title">
        {{locale.name}}:
      </div>
      <div class="organization-info__description">
        {{ name }}
      </div>
    </div>
    <div class="organization-info__wrapper" v-if="tin">
      <div class="organization-info__title">
        {{locale.tin}}:
      </div>
      <div class="organization-info__description">
        {{ voen }}
      </div>
    </div>

  </div>
</template>

<script src="./organization-info.component.ts"></script>
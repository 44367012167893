import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import TableComponent from '@/core/shared/table/table.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {IInformationOrganization} from '@/pages/detailed-information-departments-service/types/voen';

@Options({
    name: 'organization-table-component',
    props: {
        organizations: {
            type: Array
        }
    },
    components: {
        TableComponent
    }
})

export default class OrganizationTableComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public organizations: IInformationOrganization[] = [];

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.detailedInformationDepartmentsService) {
                this.locale = res.detailedInformationDepartmentsService;
            }
        });
    }
}